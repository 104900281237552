<template>
    <div>
        <particles />
        <div class="container">
            <steps :active="0" />
            <div style="flex-grow:6"></div>
            <div :style="{ width: `${image_width}px`, height: `${image_height}px` }" class="image_container">
                <van-image v-if="src" :src="src" fit="cover" alt="面象采集默认图片" style="width:100%;height:100%"></van-image>
                <svg v-else viewBox="0 0 800 800" style="width:200px;height:200px;opacity:0.8">
                    <path fill="#a5a7f7"
                        d="m75.46241,794.54515c0,0 649.42452,2.01476 649.08844,2.01475c-9.42292,-161.03393 -166.21747,-212.22145 -166.55355,-212.22145c0.33608,0 -57.42039,-60.44282 -57.75647,-60.44282c0.33608,0 -20.48311,18.80443 -20.8192,18.80443c0.33609,0 -1.67868,-59.09964 -2.01476,-59.09964c46.67558,-16.78968 90.32873,-116.18454 84.61995,-144.39119c47.34716,28.20665 91.6719,-116.18452 30.893,-106.11073c35.2586,-176.62735 -104.43147,-199.4613 -104.76755,-199.4613c-23.16946,-51.71219 -317.99612,-65.81551 -266.62,200.13289c-79.58277,3.35793 -4.36503,133.6458 16.78967,112.15501c15.78258,95.36535 81.59809,137.00373 81.26201,137.00373c0.33608,0 -0.3355,59.09965 -0.67159,59.09965c0.33608,0 -19.81151,-16.78968 -19.81151,-17.46127c0,-0.67158 -60.44283,61.11441 -60.77891,61.11441c-167.56064,76.56091 -162.85953,208.86353 -162.85953,208.86353z" />
                </svg>
                <van-button v-if="!src" style="background:none;border:none" type="success" @click="show_tip = true"> 如何拍面象
                    <van-icon name="question-o" style="line-height: 16px;vertical-align: text-top;" />
                </van-button>
            </div>
            <div style="flex-grow:1"></div>
            <van-button :type="main_type" :style="{ width: `${button_width}px`, marginTop: '20px' }" class="main_button" @click="clickPrimaryButton" :loading="loading" :loading-text="main_text">{{ main_text }}</van-button>
            <van-button type="primary" @click="clickSecondButton" size="mini" style="background:none;border:none">{{ second_text }}</van-button>
            <div style="flex-grow:3"></div>
            <van-popup v-model:show="show_tip" round position="bottom" style="height:30%;display:flex;flex-direction:column;justify-content:space-between;padding:25px 0" duration="0.15">
                <strong style="text-align:center;font-size:20px">注 意 事 项</strong>
                <span style="padding:30px;text-indent:2em;font-size:14px;line-height:16px;color:#333">为确保结果准确，请在自然光环境下拍摄，保持脸部无遮挡并使用素颜</span>
                <van-button @click="show_tip = false" type="primary" style="width:90%;margin:0 auto">我知道了</van-button>
            </van-popup>
        </div>
    </div>
</template>

<script>
import particles from "../components/particles.vue";
import steps from "../components/steps";
export default {
    components: {
        particles,
        steps,
    },
    data() {
        return {
            wx: new this.$common.WX(),
            src: "",
            next: false,
            loading: false,
            main_text: "拍摄面部图像",
            main_type: "primary",
            second_text: "从相册选择",
            image_width: 0,
            image_height: 0,
            button_width: 0,
            media_id: "",
            show_tip: false,
        };
    },
    methods: {
        clickPrimaryButton() {
            if (this.next) {
                this.$api("analyseFace", { media_id: this.media_id });
                let type = "主测评";
                if (this.$route.query.type) {
                    type = this.$route.query.type;
                }
                this.$router.push({
                    path: "/step2",
                    query: { type },
                });
            } else {
                this.loading = true;
                this.main_text = "正在加载...";
                this.second_text = "";
                this.wx
                    .shootImage()
                    .then((local_id) => {
                        this.loading = true;
                        this.main_text = "上传中...";
                        this.second_text = "";
                        this.src = local_id;
                        return this.uploadImage(local_id);
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.main_text = "拍摄面部图像";
                        this.second_text = "从相册选择";
                    });
            }
        },
        clickSecondButton() {
            if (this.loading) return;
            if (this.next) {
                this.next = false;
                this.main_text = "拍摄面部图像";
                this.main_type = "primary";
                this.second_text = "从相册选择";
                this.src = "";
            } else {
                this.wx
                    .selectImage()
                    .then((local_id) => {
                        this.loading = true;
                        this.main_text = "上传中...";
                        this.second_text = "";
                        this.src = local_id;
                        return this.uploadImage(local_id);
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.main_text = "拍摄面部图像";
                        this.second_text = "从相册选择";
                    });
            }
        },
        uploadImage(local_id) {
            this.wx
                .uploadImage(local_id)
                .then((server_id) => {
                    this.main_text = "分析中...";
                    this.media_id = server_id;
                    return this.$api("checkFace", { media_id: server_id });
                })
                .then((res) => {
                    this.loading = false;
                    if (res.result) {
                        this.next = true;
                        this.main_text = "面部图像分析完成，进入下一步";
                        this.second_text = "对图片不满意？点击重来";
                        this.main_type = "success";
                    } else {
                        this.main_text = "图像不合格，请重新拍摄";
                        this.second_text = "从相册选择";
                        this.main_type = "danger";
                        setTimeout(() => {
                            this.main_type = "primary";
                            this.main_text = "拍摄面部图像";
                        }, 1200);
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.main_text = "拍摄面部图像";
                    this.second_text = "从相册选择";
                });
        },
    },
    created() {
        window.onresize = () => {
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.image_width = calc([480, 600], [640, 840], [-60, 0.8, 0]);
            this.image_height = (this.image_width / 9) * 16;
            this.button_width = calc([560, 720], [640, 840], [-60, 1, 0]);
            // this.brain_width = calc([576, 640], [960, 1280], [-240, 0.8, 0]);
        };
        window.onresize();
    },
    mounted() {
        this.show_tip = true;
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.image_container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(30, 30, 30, 0.4);
    border: 3px #709df1 dashed;
    flex-direction: column;
}
.main_button {
    transition: all 0.35s;
}
</style>
